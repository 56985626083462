import {keyBy} from 'lodash';

import {
    FETCH_USE_CASES_SUCCESS,
    FETCH_CATEGORIES_SUCCESS,
    START_REQUEST,
    FINISH_REQUEST,
    FAILURE
} from '../actions/settingsActions';

const getSubCategoriesList = (categories) => {
    let subCategories = []
    categories.forEach(cat => {
        subCategories = subCategories.concat(cat.sub_categories.map((sub_cat) => {
            return {full_name: `${cat.name} -> ${sub_cat.name}`, ...sub_cat}
        }))
    })
    return subCategories
}

export default (state = {
    isLoading: false,
    useCases: {},
    categoryHierarchy: [],
    categories: {},
    subCategories: {}
}, action) => {
    switch (action.type) {
        case START_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case FINISH_REQUEST: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case FAILURE: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case FETCH_USE_CASES_SUCCESS: {
            return {
                ...state,
                useCases: keyBy(action.useCases, 'id'),
                isLoading: false,
            };
        }
        case FETCH_CATEGORIES_SUCCESS: {
            return {
                ...state,
                categoryHierarchy: action.categories,
                categories: keyBy(action.categories, 'id'),
                subCategories: keyBy(getSubCategoriesList(action.categories), 'full_name'),
                isLoading: false,
            };
        }
        default:
            return state;
    }
};
