import React, { useRef, useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Button as AntButton, Popconfirm } from "antd";
import { get, keyBy, isEmpty, uniq, flatten } from "lodash";
import { Button, Dropdown } from "semantic-ui-react";
import config from "react-global-configuration";
import { SearchOutlined } from "@ant-design/icons";
import { NotificationManager } from "react-notifications";
import history from "../../history";
import qs from "query-string";
import { saveAs } from "file-saver";

import {
  fetchTenantEnrichmentsSettings,
  updateTenantEnrichmentsSettings,
  updateTenantAllEnrichmentsSettings,
  updateAllClientsEnrichment,
} from "../../redux/actions/tenantsActions";
import {
  fetchUseCases,
  fetchCategories,
} from "../../redux/actions/settingsActions";
import { stageTags } from "./../../shared/data/tags";
import {
  sortArray,
  removeEmpty,
  quote,
  getTreeChildrens,
} from "../../common/utils";
import apiEnrichments from "../../api/apiEnrichments";
import apiSettings from "../../api/apiSettings";
import { protectedTenantNames } from "../../shared/data/tenants";
import { METADATA_PROTECTED_WRITE } from "../../shared/data/permissions";
import Limit from "../../shared/Components/Enrichments/Limit";
import TableMore from "../../shared/Components/TableMore";
import PopupHint from "../../shared/fields/PopupHint";
import SearchFilterDropdown from "../../shared/Components/Table/SearchFilterDropdown";
import FilterDropdown from "../../shared/Components/Table/FilterDropdown";
import AvailabilityDropdown from "./Dropdown";
import CopyTenantsSettings from "../Tenants/CopyTenantsSettings";
import ListOfListsView from "../../shared/Components/Enrichments/ListOfListsView";
import FiltersView from "../../shared/Components/FiltersView";
import TagsView from "../../shared/Components/Enrichments/TagsView";
import NameAndId from "../../shared/Components/Enrichments/Table/NameAndId";
import LegacyEnrichmentWarning from "./LegacyEnrichmentWarning";
import PremiumEnrichmentWarning from "./PremiumEnrichmentWarning";
import LabelsView from "../../shared/Components/Enrichments/LabelsView";
import { CustomTableFilter } from "../Catalog/metadata-new/custom-table-filter/CustomTableFilter";

const ignoredFilterKeys = ["pageSize", "page", "limitDirection"];

export default function TenantEnrichments({ match, auth }) {
  const { tenantName } = match.params;

  const tenantEnrichments = useSelector(
    (state) => state.tenantsReducer.clientEnrichments
  );
  const tenantEnrichmentsItemsCount = useSelector(
    (state) => state.tenantsReducer.clientEnrichmentsCount
  );
  const loading = useSelector((state) => state.tenantsReducer.isLoading);

  const [filtersTimeout, setFiltersTimeout] = useState(0);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [exporting, setExporting] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const dispatch = useDispatch();

  const searchInput = useRef();
  const searchLimitInput = useRef();
  const searchIcon = useRef();

  const initialFilters = {};
  const newFilters = qs.parse(history.location.search);
  const [filters, setFilters] = useState({ ...initialFilters, ...newFilters });
  const filtered =
    Object.keys(filters).filter((key) => !ignoredFilterKeys.includes(key))
      .length > 0;

  const useCasesMap = useSelector((state) => state.settingsReducer.useCases);

  const getTagValues = useCallback(
    (tagName) => {
      const result = uniq(
        tenantEnrichments.reduce((acc, metadata) => {
          return [...acc, ...getTagRowValues(metadata, tagName)];
        }, [])
      );

      return result;
    },
    [tenantEnrichments]
  );

  const possibleOntologies = useMemo(() => {
    const result = uniq(
      tenantEnrichments.reduce((acc, row) => {
        return [
          ...acc,
          ...flatten(
            get(row, ["metadata", "properties", "input_ontologies"]) ?? []
          ),
        ];
      }, [])
    );

    return result;
  }, [tenantEnrichments]);

  const possibleMaturityTags = useMemo(
    () => getTagValues("Maturity"),
    [getTagValues]
  );

  const possibleHealthStatusTags = useMemo(
    () => getTagValues("Health Status"),
    [getTagValues]
  );

  const possibleRegionTags = useMemo(
    () => getTagValues("Regions"),
    [getTagValues]
  );

  const allTags = useMemo(() => {
    const result = uniq(
      tenantEnrichments.reduce((acc, record) => {
        return [...acc, ...record.metadata.tags.map((x) => Object.keys(x)[0])];
      }, [])
    );

    return result;
  }, [tenantEnrichments]);

  const isLegacyEnrichmentDisabled = (record) => {
    return (
      get(record, "metadata.enrichment_type") === "legacy" &&
      !auth.permissions.includes(METADATA_PROTECTED_WRITE)
    );
  };

  const isPremiumEnrichmentDisabled = (record) => {
    return (
      get(record, "metadata.is_premium") === true &&
      !auth.permissions.includes(METADATA_PROTECTED_WRITE)
    );
  };

  const isProtectedTenantDisabled = (record) => {
    return (
      protectedTenantNames.includes(tenantName) &&
      !auth.permissions.includes(METADATA_PROTECTED_WRITE)
    );
  };

  function getTagRowValues(record, tagName) {
    const values = record.metadata.tags.find(
      (tag) => Object.keys(tag)[0] === tagName
    );
    return values?.[tagName] ?? [];
  }

  const isDisabled = (record) =>
    isLegacyEnrichmentDisabled(record) ||
    isPremiumEnrichmentDisabled(record) ||
    isProtectedTenantDisabled(record);

  const rowSelection = {
    fixed: true,
    selectedRowKeys,
    selections: [
      Table.SELECTION_INVERT,
      {
        key: "unselect",
        text: "Unselect all data",
        onSelect: () => setSelectedRowKeys([]),
      },
    ],
    onChange: (selectedRowKeys, selectedRows) => {
      const filteredSelectedKeys = selectedRows
        .filter((row) => !isDisabled(row))
        .map((row) => row.enrichment_id);
      setSelectedRowKeys(filteredSelectedKeys);

      if (filteredSelectedKeys.length !== parseInt(filters.pageSize)) {
        setAllSelected(false);
      }
    },
  };

  const columns = [
    {
      title: "Display Name, Id",
      dataIndex: "enrichment_id",
      width: "220px",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <SearchFilterDropdown
          inputRef={searchInput}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          ref={searchIcon}
          style={{ color: filtered ? "00c2b2" : "#454444", fontSize: 20 }}
        />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) selectSearchFilter();
      },
      render: (service, record) => (
        <div style={{ display: "flex" }}>
          {isLegacyEnrichmentDisabled(record) && (
            <LegacyEnrichmentWarning asTooltip />
          )}
          {isPremiumEnrichmentDisabled(record) && (
            <PremiumEnrichmentWarning asTooltip />
          )}
          <NameAndId
            id={record.metadata.properties.name}
            name={get(record, "metadata.properties.display_name")}
            href={`/catalog/${config.get("primaryStage")}/${quote(
              record.metadata.name
            )}`}
          />
        </div>
      ),
      filteredValue: filters.__search_text__ || null,
    },
    {
      title: "Supported ontologies",
      dataIndex: ["metadata", "properties", "input_ontologies"],
      key: "input_ontologies",
      width: "250px",
      filterDropdown: ({
        selectedKeys,
        setSelectedKeys,
        confirm,
        clearFilters,
        visible,
      }) => (
        <FilterDropdown
          visible={visible}
          placeholder="Search"
          filters={[...possibleOntologies].map((record) => {
            return { text: record, value: record };
          })}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={(_, confirm) => confirm()}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      render: (input_ontologies) => (
        <ListOfListsView value={input_ontologies} />
      ),
      filteredValue: filters.input_ontologies || null,
    },
    {
      title: "Availability",
      dataIndex: "availability",
      width: "150px",
      filters: [
        { text: "Disable", value: "0" },
        { text: "Deployed projects only", value: "1" },
        { text: "Active", value: "2" },
      ],
      render: (availability, record) => (
        <AvailabilityDropdown
          resource_name="Enrichment"
          disabled={isDisabled(record)}
          value={availability}
          onChange={(v) => onAvailabilityChange(v, record)}
        />
      ),
      filteredValue: filters.availability || null,
    },
    {
      title: "Limit",
      dataIndex: "limit",
      width: "70px",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <SearchFilterDropdown
          inputRef={searchLimitInput}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      render: (limit, record) => (
        <Limit
          disabled={isDisabled(record)}
          loading={loading}
          defaultLimit={record.defaultLimit}
          value={limit}
          editing={editingIndex === record.enrichment_id}
          onStartEditing={() => setEditingIndex(record.enrichment_id)}
          onCancelEditing={() => setEditingIndex(null)}
          onValueChange={(newLimit) => onLimitChange(record, newLimit)}
        />
      ),
      filteredValue: filters.limit || null,
    },
    {
      key: "Maturity",
      title: "Maturity",
      width: "100px",
      filterDropdown: ({
        selectedKeys,
        setSelectedKeys,
        confirm,
        clearFilters,
        visible,
      }) => (
        <FilterDropdown
          visible={visible}
          placeholder="Search"
          filters={possibleMaturityTags.map((tag) => {
            return { text: tag, value: tag };
          })}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={(_, confirm) => confirm()}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      render: (record) => {
        return <LabelsView value={getTagRowValues(record, "Maturity")} />;
      },
      filteredValue: filters.Maturity || null,
    },
    {
      key: "Health Status",
      title: "Health Status",
      width: "100px",
      filterDropdown: ({
        selectedKeys,
        setSelectedKeys,
        confirm,
        clearFilters,
        visible,
      }) => (
        <FilterDropdown
          visible={visible}
          placeholder="Search"
          filters={possibleHealthStatusTags.map((tag) => {
            return { text: tag, value: tag };
          })}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={(_, confirm) => confirm()}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      render: (record) => {
        return <LabelsView value={getTagRowValues(record, "Health Status")} />;
      },
      filteredValue: filters["Health Status"] || null,
    },
    {
      title: "Region",
      key: "Regions",
      width: "8%",
      filterDropdown: ({
        selectedKeys,
        setSelectedKeys,
        confirm,
        clearFilters,
        visible,
      }) => (
        <FilterDropdown
          visible={visible}
          placeholder="Search"
          filters={possibleRegionTags.map((tag) => {
            return { text: tag, value: tag };
          })}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={(_, confirm) => confirm()}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      render: (record) => {
        return <LabelsView value={getTagRowValues(record, "Regions")} />;
      },
      filteredValue: filters.Regions || null,
    },
    {
      title: "",
      dataIndex: "service",
      width: "2%",
      fixed: "right",
      render: (labels, record) => (
        <TableMore>
          <Popconfirm
            disabled={loading}
            title="Are you sure to apply to all tenants except default and tenants with 'NOT_BULK_UPDATABLE' tag?"
            onConfirm={() => onApplyAvailabilityToAll(record)}
            okText="Apply"
          >
            <AntButton>Apply availability to all tenants</AntButton>
          </Popconfirm>
        </TableMore>
      ),
    },
  ];

  useEffect(() => {
    document.title = `${tenantName} enrichments`;
    if (tenantName) fetchData();
  }, [tenantName]);

  function fetchData(newFilters = filters) {
    setEditingIndex(-1);
    if ("limit" in newFilters) {
      newFilters = handleLimitSearch(newFilters);
    }
    history.push({ search: qs.stringify(newFilters) });
    dispatch(fetchTenantEnrichmentsSettings(tenantName, newFilters));

    if (isEmpty(useCasesMap)) {
      dispatch(fetchUseCases());
      dispatch(fetchCategories());
    }
  }

  function onAvailabilityChange(value, enrichmentSettings) {
    dispatch(
      updateTenantEnrichmentsSettings(
        tenantName,
        [
          {
            availability: value,
            enrichment_id: enrichmentSettings.metadata.properties.id,
            enrichment_name: enrichmentSettings.metadata.properties.name,
            tenant: enrichmentSettings.tenant,
            limit: enrichmentSettings.limit,
          },
        ],
        fetchData
      )
    );
  }

  function onApplyAvailabilityToAll(enrichmentSettings) {
    dispatch(
      updateAllClientsEnrichment(
        {
          availability: enrichmentSettings.availability,
          enrichment_id: enrichmentSettings.metadata.properties.id,
          enrichment_name: enrichmentSettings.metadata.properties.name,
          tenant: enrichmentSettings.tenant,
          limit: enrichmentSettings.limit,
        },
        fetchData
      )
    );
  }

  function onLimitChange(enrichment, limit) {
    const enrichmentSettings = {
      limit,
      availability: enrichment.availability,
      enrichment_id: enrichment.metadata.properties.id,
      enrichment_name: enrichment.metadata.properties.name,
    };

    dispatch(
      updateTenantEnrichmentsSettings(
        tenantName,
        [enrichmentSettings],
        fetchData
      )
    );
  }

  async function onExport() {
    try {
      setExporting(true);

      const response = await apiEnrichments.exportTenantEnrichments(
        tenantName,
        filters
      );
      saveAs(response.data, `${tenantName}_enrichments_settings.csv`);
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      setExporting(false);
    }
  }

  function onApplyAvailabilityToAllSelected(availability) {
    const tenantEnrichmentsMap = keyBy(tenantEnrichments, "enrichment_id");

    if (allSelected) {
      dispatch(
        updateTenantAllEnrichmentsSettings(
          tenantName,
          { availability },
          filters,
          fetchData
        )
      );
    } else {
      dispatch(
        updateTenantEnrichmentsSettings(
          tenantName,
          selectedRowKeys.map((r) => {
            return {
              availability,
              enrichment_id: tenantEnrichmentsMap[r].metadata.properties.id,
              enrichment_name: tenantEnrichmentsMap[r].metadata.properties.name,
              limit: tenantEnrichmentsMap[r].limit,
              tenantName,
            };
          }),
          fetchData
        )
      );
    }

    setSelectedRowKeys([]);
    setAllSelected(false);
  }

  function handleSearch(searchText, confirm) {
    confirm();
  }

  function handleReset(clearFilters) {
    clearFilters();
  }

  function selectSearchFilter() {
    setTimeout(() => searchInput.current.select());
  }

  document.addEventListener("keydown", (e) => {
    let charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.metaKey || e.ctrlKey) && charCode === "f") onControlFPress(e);
  });

  function onControlFPress(e) {
    if (!searchInput.current || !searchInput.current.state.focused) {
      setTimeout(() => searchIcon.current.click());
      setTimeout(() => searchInput.current.focus(), 100);
      e.returnValue = false;
    }
  }

  function handleLimitSearch(filters) {
    filters = { ...filters };
    if (filters.limit.startsWith("<")) {
      filters["limitDirection"] = "lte";
      filters["limit"] = filters.limit.replaceAll("<", "");
    } else if (filters.limit.startsWith(">")) {
      filters["limitDirection"] = "gte";
      filters["limit"] = filters.limit.replaceAll(">", "");
    } else {
      filters["limitDirection"] = "eq";
    }
    return filters;
  }

  function handleFiltersChange(newFilters, pause = 1400) {
    setFilters(newFilters);

    if (filtersTimeout) {
      clearTimeout(filtersTimeout);
    }

    setFiltersTimeout(
      setTimeout(() => {
        fetchData(newFilters);
      }, pause)
    );
  }

  function handleNewFilters(newFilters) {
    const filtersToApply = {
      ...filters,
      ...newFilters,
      __search_text__: newFilters.enrichment_id,
    };

    delete filtersToApply['enrichment_id'];

    removeEmpty(filtersToApply);

    handleFiltersChange(filtersToApply);
  }

  function handleTableChange(pagination, newFilters, sorter) {
    handleNewFilters(newFilters);
  }

  return (
    <>
      <div>
        <Dropdown
          disabled={selectedRowKeys.length === 0}
          text="Availability"
          icon="angle down"
          className="icon"
          floating
          labeled
          button
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => onApplyAvailabilityToAllSelected(0)}>
              Make Disable
            </Dropdown.Item>
            <Dropdown.Item onClick={() => onApplyAvailabilityToAllSelected(1)}>
              Make Deployed projects only
            </Dropdown.Item>
            <Dropdown.Item onClick={() => onApplyAvailabilityToAllSelected(2)}>
              Make Active
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <CustomTableFilter
          allFieldNames={allTags}
          getFieldValues={(tagName) =>
            getTagValues(tagName).map((value) => ({ value, label: value }))
          }
          onApply={(fieldName, fieldValues) => {
            handleNewFilters({
              [fieldName]: fieldValues
            });
          }}
        />
        <span style={{ marginLeft: 8 }}>
          {allSelected ? (
            <>
              {filtered ? "All filtered" : `All ${tenantEnrichmentsItemsCount}`}{" "}
              enrichments are selected.
              <AntButton type="link" onClick={() => setAllSelected(false)}>
                Clear selection
              </AntButton>
            </>
          ) : (
            <>
              {selectedRowKeys.length > 0 &&
                `Selected ${selectedRowKeys.length} enrichments.`}
            </>
          )}
        </span>
        <CopyTenantsSettings
          floated="right"
          allowEnrichments
          hint="Export enrichments settings to"
          tenantName={tenantName}
          loading={loading}
        />
        <PopupHint content="Export as CSV">
          <Button
            basic
            icon={exporting ? "spinner" : "download"}
            size="mini"
            floated="right"
            disabled={loading || exporting}
            loading={exporting}
            onClick={onExport}
          />
        </PopupHint>
        <br />
      </div>
      {filtered && (
        <>
          <br />
          <FiltersView
            value={filters}
            onChange={handleFiltersChange}
            ignoredKeys={ignoredFilterKeys}
          />
        </>
      )}
      <p style={{ fontSize: "18px", float: "right" }}>
        Total: {tenantEnrichments.length}
      </p>
      <Table
        loading={loading}
        dataSource={tenantEnrichments}
        columns={columns}
        rowSelection={rowSelection}
        onChange={handleTableChange}
        rowKey={(record) => record.enrichment_id}
        size="middle"
        tableLayout="fixed"
        scroll={{ x: 1600 }}
        pagination={false}
      />
    </>
  );
}
