import { NotificationManager } from 'react-notifications';
import settingsReducer from '../reducers/settingsReducer';
import apiClient from '../../api/apiClient';

export const FETCH_USE_CASES_SUCCESS = 'FETCH_USE_CASES_SUCCESS';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const START_REQUEST = 'START_REQUEST';
export const FINISH_REQUEST = 'FINISH_REQUEST';
export const FAILURE = 'FAILURE';

export const fetchCategoriesSuccess = categories => ({ type: FETCH_CATEGORIES_SUCCESS, categories });

export const fetchUseCasesSuccess = useCases => ({ type: FETCH_USE_CASES_SUCCESS, useCases });

export const fetchFailure = errorMessage => {
  NotificationManager.error(errorMessage);
  return { type: FAILURE, errorMessage };
};

export const startRequest = () => ({ type: START_REQUEST });

export const fetchUseCases = () => (dispatch) => {
  console.log(settingsReducer)
  dispatch(startRequest());
  apiClient.get(`/settings/use_cases`).then((response) => {
    dispatch(fetchUseCasesSuccess(response.data));
  }).catch((error) => {
    dispatch(fetchFailure(error.message));
  });
};

export const fetchCategories = () => (dispatch) => {
  dispatch(startRequest());
  apiClient.get('/settings/category_hierarchy').then((response) => {
    dispatch(fetchCategoriesSuccess(response.data));
  }).catch((error) => {
    dispatch(fetchFailure(error.message));
  });
};
