import React, {useEffect, useState} from 'react';
import { Tabs } from 'antd';
import {useDispatch, useSelector} from 'react-redux';

import CatalogItems from './CatalogItems';
import {fetchCategories, fetchUseCases} from "../../redux/actions/settingsActions";
import {isEmpty} from "lodash";

const { TabPane } = Tabs;

export default function Catalog({ auth, history, match }) {
  const { params } = match;
  const { environment } = params;
  const [env, setEnv] = useState(environment);
  const dispatch = useDispatch();
  const useCasesMap = useSelector(state => state.settingsReducer.useCases);

  function handleTabClick(key) {
    history.push(`/catalog/${key}`);
    setEnv(key);
  }

  function fetchData() {
    if (isEmpty(useCasesMap)){
      dispatch(fetchUseCases());
      dispatch(fetchCategories());
    }
  }

  fetchData()

  return (
    <>
      <Tabs defaultActiveKey={environment} onChange={handleTabClick}>
        <TabPane tab='Production' key='production'>
        </TabPane>
        <TabPane tab='Develop' key='develop'>
        </TabPane>
      </Tabs>
      <CatalogItems environment={env} auth={auth}/>
    </>
  );
};
